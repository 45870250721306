///*------------------------------------*//
//    Fonts
//*------------------------------------*//
$font-family: "Times New Roman", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
$line-height: 1.5;




///*------------------------------------*//
//    Colors#db4c34//#231815
//*------------------------------------*//
$main-color: #e6644f;
$accent-color: #f00;
$font-color: #333333;
$base-color: #fff;
$bg-color: #fff;




///*------------------------------------*//
//    path
//*------------------------------------*//
$img_path:'./htdocs/common/img/';







///*------------------------------------*//
//     Placeholder selector
//*------------------------------------*//

//clearfix
%cf{
	&:after {
    content:"";
    display:block;
    clear:both;
  }
}



///*------------------------------------*//
//     Mixin
//*------------------------------------*//

//メディアクエリ挿入
@mixin media_query($width:640){
	@media screen and (max-width: $width+px){
		@content;
	}
}
