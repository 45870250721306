@charset "UTF-8";

html,body{
  height: 100%;
}

/*------------------------------------*\
    HEDAER
\*------------------------------------*/
.header{
  position: relative;
  padding: 0 20px;
  
  @include media_query(){
  	height: 100%;
  }
}


/* ロゴ */
.logo{
  text-align: center;
  padding: 87px 0 128px;
  
  @include media_query(){
  	height: 100%;
  	display: flex;
  	justify-content: center;
  	align-items: center;
  }
  
  svg {
    height: 255px;
    
    @include media_query(){
    	margin-top: -10px;
    }
  }
}

#logo{
  .logo_img{
    fill: #db4c34;
  }
  .logo_text{
    fill: #231815;
  }
}




.menu_btn{
  display: none;
  
  position: fixed;
  right: 10px;
  top: 10px;

  height: 50px;
  width: 50px;
  border: solid 1px $main-color;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  color: $main-color;
  font-size: 1.4rem;
  z-index: 99999;
  background-color: rgba(#fff, .8);
  
  @include media_query(){
  	display: block;
  	cursor: pointer;
  }
}
/* グローバルナビゲーション */
.gnav{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;
  
  @include media_query(){
    display: none;
  	position: fixed;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  	z-index: 9999;
  	background-color: rgba(#2f2f2f, .8);
  }
  
  ul{
    display: flex;
    justify-content: center;
    
    @include media_query(){
    	flex-direction: column;
    }
    
    li{
      font-size: 1.5rem;
      
      @include media_query(){
      	font-size: 1.7rem;
      }
      
      &:not(:last-child){
        margin-right: 50px;
        
        @include media_query(){
        	margin-right: 0;
        }
      }
      
      a{
        color: $font-color;
        padding-bottom: .2em;
        letter-spacing: .08em;
        
        @include media_query(){
        	display: block;
        	padding: .5em 1em;
        	text-align: center;
        	color: #fff;
        }
        
        &:hover{
          border-bottom: solid 1px #231815;
          
          @include media_query(){
          	border: none;
          	background-color: #000;
          }
        }
      }
    }
  }
}

.greeting_title{
  font-size: 2.2rem;
  margin-bottom: 1em;
  
  &_sub{
    font-size: 2.2rem;
    margin: 3em 0 1em;
    
    &+p{
      font-size: 1.3rem;
    }
  }
}


.fixed{
  position: fixed;
  top: 0;
  left: 0;
  bottom: auto;
  width: 100%;
  z-index: 9999;
  background-color: #fff;
}


.defs{
  display: none;
}



.footer{
  padding: 150px 0 20px;
  text-align: center;
  font-size: 1.3rem;
}


