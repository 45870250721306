@charset "UTF-8";

.contact{
  max-width: 800px;
  padding: 100px 20px 0;
  margin: 0 auto;
    
  display: flex;
  
  @include media_query(){
      display: block;
  }
  
  .lnav{
      @include media_query(){
          display: none;
      }
  }
  
  .pan{
    margin-bottom: 40px;
  }
}

.contact_main{
  width: 100%;
  
  form{
    width: 100%;
  }
  
  table{
    width: 100%;
    
    @include media_query(){
        display: block;
    }
    
    tr{
        @include media_query(){
            width: 100%;
            display: block;
        }
      
      &:nth-child(4){
        th{
          vertical-align: top;
        }
      }
      
      th, td{
        padding: 5px 0 20px;
        @include media_query(){
            width: 100%;
            display: block;
        }
      }
      
      th{
        width: 10em;
        white-space: nowrap;
        vertical-align: middle;
        padding-right: 20px;
        text-align: left;
        font-weight: normal;
        
        label{
          font-size: 1.5rem;
        }
      }
      
      td{
        font-size: 1.5rem;
        
        input[type=text],input[type=email],input[type=submit],textarea{
          margin: 0;
          padding: 0;
          background: none;
          border: none;
          border-radius: 0;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          
          border: solid 1px rgba(#231815, .25);
          display: block;
          width: 100%;
          font-size: 1.5rem;
          padding: .5em 1em;
        }
        
        input[type=submit]{
          max-width: 150px;
          margin: 0 auto;
          color: #fff;
          background-color: $main-color;
          border: solid 1px $main-color;
          
          &:hover{
            background-color: #fff;
            color: $main-color;
          }
        }
      }
    }
  }
}

.contact_list{
  dl{
    .contact_list_item{
      display: flex;
      align-items: center;
      
      & + .contact_list_item{
        margin-top: 35px;
      }
      
      dt{
        font-size: 18px;
        margin-right: 40px;
        
        @include media_query(){
          font-size: 16px;
          margin-right: 20px;
        }
      }
      
      dd{
        font-size: 18px;
        line-height: 1;
        
        @include media_query(){
          font-size: 16px;
        }
        
        .tel{
          color: #231815;
          border-bottom: solid 1px #DB4C34;
          
          &:hover{
            border: none;
          }
        }
        
        img{
          width: 100%;
          max-width: 160px;
          height: auto;
        }
      }
    }
  }
}