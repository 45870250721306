@charset "UTF-8";
/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html {
  box-sizing: border-box;
  overflow-y: scroll;
  /* All browsers without overlaying scrollbars */
  -webkit-text-size-adjust: 100%;
  /* iOS 8+ */
}

*,
::before,
::after {
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit;
  /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
  vertical-align: inherit;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

input, select {
  vertical-align: middle;
}

input[type=button], input[type=submit] {
  -webkit-appearance: none;
}

img {
  vertical-align: bottom;
}

html {
  font-size: 62.5%;
}

body {
  color: #333333;
  font-family: "Times New Roman", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  line-height: 1.5;
  background-color: #fff;
}

::-moz-selection, ::selection {
  background: #b3d4fc;
  text-shadow: none;
}

h1 {
  font-weight: normal;
}

h2 {
  font-weight: normal;
}

h3 {
  font-weight: normal;
}

h4 {
  font-weight: normal;
}

h5 {
  font-weight: normal;
}

h6 {
  font-weight: normal;
}

a {
  color: #f00;
  text-decoration: none;
}

li {
  list-style: none;
}

button,
input,
select,
textarea {
  color: #333333;
}
button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
}

textarea {
  resize: vertical;
}

label,
input[type=submit] {
  cursor: pointer;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 30px;
  width: 55px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  /*
      &:hover, &:focus {
          outline: none;
          background: transparent;
          color: transparent;
          &:before {
              opacity: $slick-opacity-on-hover;
          }
      }
  */
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  background-image: url(../img/arr_l.png);
  background-size: 18px;
  background-repeat: no-repeat;
  left: -35px;
  /*
      &:before {
          content: $slick-prev-character;
          [dir="rtl"] & {
              content: $slick-next-character;
          }
      }
  */
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-next {
  background-image: url(../img/arr_r.png);
  background-size: 18px;
  background-repeat: no-repeat;
  right: -70px;
  /*
      &:before {
          content: $slick-next-character;
          [dir="rtl"] & {
              content: $slick-prev-character;
          }
      }
  */
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

html, body {
  height: 100%;
}

/*------------------------------------*\
    HEDAER
\*------------------------------------*/
.header {
  position: relative;
  padding: 0 20px;
}
@media screen and (max-width: 640px) {
  .header {
    height: 100%;
  }
}

/* ロゴ */
.logo {
  text-align: center;
  padding: 87px 0 128px;
}
@media screen and (max-width: 640px) {
  .logo {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.logo svg {
  height: 255px;
}
@media screen and (max-width: 640px) {
  .logo svg {
    margin-top: -10px;
  }
}

#logo .logo_img {
  fill: #db4c34;
}
#logo .logo_text {
  fill: #231815;
}

.menu_btn {
  display: none;
  position: fixed;
  right: 10px;
  top: 10px;
  height: 50px;
  width: 50px;
  border: solid 1px #e6644f;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  color: #e6644f;
  font-size: 1.4rem;
  z-index: 99999;
  background-color: rgba(255, 255, 255, 0.8);
}
@media screen and (max-width: 640px) {
  .menu_btn {
    display: block;
    cursor: pointer;
  }
}

/* グローバルナビゲーション */
.gnav {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;
}
@media screen and (max-width: 640px) {
  .gnav {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(47, 47, 47, 0.8);
  }
}
.gnav ul {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 640px) {
  .gnav ul {
    flex-direction: column;
  }
}
.gnav ul li {
  font-size: 1.5rem;
}
@media screen and (max-width: 640px) {
  .gnav ul li {
    font-size: 1.7rem;
  }
}
.gnav ul li:not(:last-child) {
  margin-right: 50px;
}
@media screen and (max-width: 640px) {
  .gnav ul li:not(:last-child) {
    margin-right: 0;
  }
}
.gnav ul li a {
  color: #333333;
  padding-bottom: 0.2em;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 640px) {
  .gnav ul li a {
    display: block;
    padding: 0.5em 1em;
    text-align: center;
    color: #fff;
  }
}
.gnav ul li a:hover {
  border-bottom: solid 1px #231815;
}
@media screen and (max-width: 640px) {
  .gnav ul li a:hover {
    border: none;
    background-color: #000;
  }
}

.greeting_title {
  font-size: 2.2rem;
  margin-bottom: 1em;
}
.greeting_title_sub {
  font-size: 2.2rem;
  margin: 3em 0 1em;
}
.greeting_title_sub + p {
  font-size: 1.3rem;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  bottom: auto;
  width: 100%;
  z-index: 9999;
  background-color: #fff;
}

.defs {
  display: none;
}

.footer {
  padding: 150px 0 20px;
  text-align: center;
  font-size: 1.3rem;
}

.tellink {
  color: #333;
  text-decoration: none;
  border-bottom: solid 1px #db4c34;
}
.tellink:hover {
  border: none;
}

/*------------------------------------*\
    HERO
\*------------------------------------*/
.hero {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}
@media screen and (max-width: 640px) {
  .hero {
    height: 200px;
  }
}
.hero ul {
  width: 100%;
  height: 525px;
  text-align: left;
  overflow: hidden;
  position: relative;
}
.hero ul li {
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  position: absolute;
}
.hero ul li img {
  width: 100%;
  height: auto;
}

.news_ul {
  margin-bottom: 30px;
}
.news_ul li {
  font-size: 1.8rem;
}
.news_ul li:not(:last-child) {
  margin-bottom: 20px;
}
.news_ul li a {
  color: #333333;
}
.news_ul li a:hover {
  color: #e6644f;
}
.news_ul li time {
  font-size: 1.2rem;
  margin-right: 30px;
  vertical-align: text-top;
}
@media screen and (max-width: 640px) {
  .news_ul li time {
    display: block;
  }
}

.more_btn a {
  color: #333333;
}
.more_btn a:hover {
  color: #e6644f;
}

/*------------------------------------*\
    MAIN
\*------------------------------------*/
.main_inner {
  max-width: 1400px;
  margin: 0 auto;
  border-bottom: solid 1px rgba(35, 24, 21, 0.005);
  padding-bottom: 110px;
}
@media screen and (max-width: 640px) {
  .main_inner {
    padding-bottom: 50px;
  }
}

.art {
  padding: 190px 20px 0;
  margin-top: -80px;
}
@media screen and (max-width: 640px) {
  .art {
    margin-top: 0;
    padding-top: 50px;
  }
}
.art_sec {
  display: flex;
  max-width: 720px;
  margin: 0 auto;
}
@media screen and (max-width: 640px) {
  .art_sec {
    flex-direction: column;
  }
}
.art_sec_title {
  width: 29%;
}
@media screen and (max-width: 640px) {
  .art_sec_title {
    width: 100%;
  }
}
.art_sec_title h2 {
  max-height: 10em;
  font-size: 2.2rem;
  writing-mode: vertical-rl;
  text-orientation: upright;
  letter-spacing: 0.3em;
  padding-top: 0.3em;
}
@media screen and (max-width: 640px) {
  .art_sec_title h2 {
    writing-mode: horizontal-tb;
    margin-bottom: 30px;
  }
}
.art_sec_main {
  width: 71%;
  flex: 1;
}
@media screen and (max-width: 640px) {
  .art_sec_main {
    width: 100%;
  }
}
.art_sec_main p {
  font-size: 1.5rem;
  line-height: 2.1;
  text-align: justify;
}
.art_sec_main_inner:not(:last-child) {
  margin-bottom: 60px;
}
.art_sec_main_inner h3 {
  font-size: 18px;
  margin-bottom: 1em;
  letter-spacing: 0.05em;
}
.art_sec_main_inner h3 small {
  font-size: 1.2rem;
}
.art_sec_main_inner .q_title {
  color: #e6644f;
}
.art_sec_main_wrap {
  display: flex;
  margin-bottom: 50px;
}
@media screen and (max-width: 640px) {
  .art_sec_main_wrap {
    flex-direction: column;
    margin-bottom: 30px;
  }
}
.art_sec_main_arr {
  width: 140px;
  background-image: url(../img/arr_long.png);
  background-repeat: no-repeat;
  background-position: 40px bottom;
  background-size: 10px;
}
@media screen and (max-width: 640px) {
  .art_sec_main_arr {
    width: auto;
    background: none;
  }
}
.art_sec_main_arr h3 {
  font-size: 1.8rem;
  background-color: #fff;
  padding-bottom: 20px;
}
@media screen and (max-width: 640px) {
  .art_sec_main_arr h3 {
    text-align: left;
  }
}
.art_sec_main .arr_none {
  background: none;
}
.art_sec_main_text {
  flex: 1;
  padding-left: 20px;
}
@media screen and (max-width: 640px) {
  .art_sec_main_text {
    padding-left: 0;
  }
}
.art_sec_main_text h3 {
  font-size: 1.8rem;
  color: #e6644f;
  margin-bottom: 1em;
  text-align: center;
}
.art_sec_main_img {
  width: 200px;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .art_sec_main_img {
    width: auto;
    margin-bottom: 10px;
  }
}
.art_sec_img {
  max-width: 980px;
  margin: 110px auto 0;
}
@media screen and (max-width: 640px) {
  .art_sec_img {
    margin: 30px auto 0;
  }
}
.art_sec_img ul {
  width: 100%;
  display: flex;
  margin-left: -2%;
}
@media screen and (max-width: 640px) {
  .art_sec_img ul {
    flex-direction: column;
    margin-left: 0;
  }
}
.art_sec_img ul li {
  width: 31.33%;
  overflow: hidden;
  margin-left: 2%;
}
@media screen and (max-width: 640px) {
  .art_sec_img ul li {
    width: 100%;
    margin-left: 0;
  }
  .art_sec_img ul li:not(:last-child) {
    margin-bottom: 20px;
  }
}
.art_sec_img ul li img {
  width: 100%;
  height: auto;
}

#about .art_sec_main_inner p, #qa .art_sec_main_inner p, #consultation .art_sec_main_text p, #selfcheck .art_sec_main_text p #staff .art_sec_main_inner p {
  font-size: 1.4rem;
  letter-spacing: 0.05em;
}
#about .art_sec_main_inner p a:hover img, #qa .art_sec_main_inner p a:hover img, #consultation .art_sec_main_text p a:hover img, #selfcheck .art_sec_main_text p #staff .art_sec_main_inner p a:hover img {
  opacity: 0.7;
}

#selfcheck .art_sec_main_wrap:not(:last-child) {
  margin-bottom: 90px !important;
}

#selfcheck {
  margin-bottom: 55px;
}

#qa {
  margin-bottom: 15px;
}

#shop_info .art_sec_main p {
  font-size: 1.8rem;
}

.line_btn {
  margin: 0.8em 0;
}

#art_sec_main_area h5 {
  font-size: 18px;
  border-bottom: solid 1px #db4c34;
  padding-bottom: 10px;
}
#art_sec_main_area h5 + p {
  margin-top: 15px;
}
#art_sec_main_area p {
  font-size: 1.4rem;
  letter-spacing: 0.05em;
}
#art_sec_main_area p + h5 {
  margin-top: 50px;
}

.map {
  margin-top: 80px;
}
@media screen and (max-width: 640px) {
  .map {
    margin-top: 30px;
  }
}

.staff_img {
  margin-bottom: 60px;
  max-width: 100%;
  height: auto;
}

.url a {
  text-decoration: underline;
  color: #333333;
  letter-spacing: 0.05em;
}
.url a:hover {
  text-decoration: none;
  color: #e6644f;
}

.blog {
  border-bottom: solid 1px rgba(35, 24, 21, 0.005);
  padding-bottom: 60px;
  margin-bottom: 20px;
}
.blog_art:not(:last-child) {
  margin-bottom: 50px;
}
@media screen and (max-width: 640px) {
  .blog_art:not(:last-child) {
    margin-bottom: 20px;
  }
}
.blog_time {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 10px;
  letter-spacing: 0.05em;
}
.blog_title {
  font-size: 1.8rem;
  margin-bottom: 5px;
  letter-spacing: 0.05em;
}
.blog_title a {
  color: #db4c34;
}
.blog_cat {
  font-size: 1.2rem !important;
  letter-spacing: 0.05em;
}
.blog_cat a {
  color: #db4c34;
}

.read_more {
  margin-bottom: 30px;
}
.read_more a {
  color: #333333;
}
.read_more a:hover {
  color: #e6644f;
}

.all_cat {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
}
.all_cat:before {
  content: "カテゴリ：";
}
.all_cat li:not(:last-child)::after {
  content: " / ";
}
.all_cat li a {
  color: #333333;
  padding: 10px;
}
.all_cat li a:hover {
  color: #e6644f;
}

.shop_slider {
  max-width: 930px;
  margin: -82px auto 0;
  padding-top: 82px;
}
@media screen and (max-width: 640px) {
  .shop_slider {
    margin-top: 0;
    padding-top: 0;
  }
}
.shop_slider .slick-prev, .shop_slider .slick-next {
  height: 36px !important;
  opacity: 0.8;
}
.shop_slider .slick-prev:before,
.shop_slider .slick-next:before {
  color: black;
}
.shop_slider img {
  width: 100%;
  height: auto;
}
.shop_slider_main {
  margin-bottom: 25px;
}
.shop_slider_nav {
  margin-left: -24px;
}
@media screen and (max-width: 640px) {
  .shop_slider_nav {
    display: none;
  }
}
.shop_slider_nav li {
  margin-left: 24px;
}

.tal {
  text-align: left !important;
}

.pan {
  margin-bottom: 30px;
}
@media screen and (max-width: 640px) {
  .pan {
    margin-bottom: 20px;
  }
}
.pan ul {
  display: flex;
  align-items: center;
}
.pan ul li {
  font-size: 1.2rem;
}
.pan ul li:not(:last-child)::after {
  content: ">";
  padding: 0 5px;
}
.pan ul li a {
  color: #333333;
}
.pan ul li a:hover {
  color: #e6644f;
}

.single {
  max-width: 780px;
  padding: 100px 20px 0;
  margin: 0 auto;
  display: flex;
}
@media screen and (max-width: 640px) {
  .single {
    max-width: 100%;
    flex-direction: column;
    padding: 20px 20px 0;
  }
}
.single_main {
  max-width: 520px;
}
@media screen and (max-width: 640px) {
  .single_main {
    max-width: 100%;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: solid 1px #ccc;
  }
}
.single_info time {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 30px;
}
@media screen and (max-width: 640px) {
  .single_info time {
    margin-bottom: 20px;
  }
}
.single_info h3 {
  color: #e6644f;
  font-size: 2.2rem;
  margin-bottom: 0.5em;
}
.single_info .cat {
  margin-bottom: 30px;
  font-size: 1.2rem;
}
@media screen and (max-width: 640px) {
  .single_info .cat {
    margin-bottom: 20px;
  }
}
.single_info .cat a {
  color: #333333;
}
.single_info .cat a:hover {
  color: #e6644f;
}
.single_art_main p {
  font-size: 1.5rem;
  line-height: 2;
}
.single_art_main img {
  vertical-align: bottom;
  width: 100%;
  height: auto;
  margin: 30px 0;
}

.lnav {
  width: 100px;
  margin-right: 120px;
}
@media screen and (max-width: 640px) {
  .lnav {
    width: 100%;
    order: 2;
  }
}
.lnav h2 {
  max-height: 10em;
  font-size: 2.2rem;
  writing-mode: vertical-rl;
  text-orientation: upright;
  letter-spacing: 0.3em;
  padding-top: 0.3em;
  margin: 50px 0 100px;
}
@media screen and (max-width: 640px) {
  .lnav h2 {
    display: none;
  }
}

.cat_list h4, .archive_list h4 {
  font-size: 1.2rem;
  margin-bottom: 0.8em;
}
@media screen and (max-width: 640px) {
  .cat_list ul, .archive_list ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.cat_list ul li, .archive_list ul li {
  font-size: 1.2rem;
}
@media screen and (max-width: 640px) {
  .cat_list ul li, .archive_list ul li {
    width: 48%;
  }
}
.cat_list ul li:not(:last-child), .archive_list ul li:not(:last-child) {
  margin-bottom: 0.8em;
}
.cat_list ul li a, .archive_list ul li a {
  display: block;
  color: #333333;
}
@media screen and (max-width: 640px) {
  .cat_list ul li a, .archive_list ul li a {
    padding: 0.5em 0;
  }
}
.cat_list ul li a:hover, .archive_list ul li a:hover {
  color: #e6644f;
}
.cat_list ul li a::before, .archive_list ul li a::before {
  content: "▷";
  margin-right: 5px;
}

.cat_list {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .cat_list {
    margin-bottom: 30px;
  }
}

.sp_title {
  display: none;
  font-size: 2.2rem;
  letter-spacing: 0.3em;
  padding-top: 0.3em;
  margin-bottom: 30px;
}
@media screen and (max-width: 640px) {
  .sp_title {
    display: block;
  }
}

.single_art .blog {
  border: none;
}

.contact {
  max-width: 800px;
  padding: 100px 20px 0;
  margin: 0 auto;
  display: flex;
}
@media screen and (max-width: 640px) {
  .contact {
    display: block;
  }
}
@media screen and (max-width: 640px) {
  .contact .lnav {
    display: none;
  }
}
.contact .pan {
  margin-bottom: 40px;
}

.contact_main {
  width: 100%;
}
.contact_main form {
  width: 100%;
}
.contact_main table {
  width: 100%;
}
@media screen and (max-width: 640px) {
  .contact_main table {
    display: block;
  }
}
@media screen and (max-width: 640px) {
  .contact_main table tr {
    width: 100%;
    display: block;
  }
}
.contact_main table tr:nth-child(4) th {
  vertical-align: top;
}
.contact_main table tr th, .contact_main table tr td {
  padding: 5px 0 20px;
}
@media screen and (max-width: 640px) {
  .contact_main table tr th, .contact_main table tr td {
    width: 100%;
    display: block;
  }
}
.contact_main table tr th {
  width: 10em;
  white-space: nowrap;
  vertical-align: middle;
  padding-right: 20px;
  text-align: left;
  font-weight: normal;
}
.contact_main table tr th label {
  font-size: 1.5rem;
}
.contact_main table tr td {
  font-size: 1.5rem;
}
.contact_main table tr td input[type=text], .contact_main table tr td input[type=email], .contact_main table tr td input[type=submit], .contact_main table tr td textarea {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: solid 1px rgba(35, 24, 21, 0.25);
  display: block;
  width: 100%;
  font-size: 1.5rem;
  padding: 0.5em 1em;
}
.contact_main table tr td input[type=submit] {
  max-width: 150px;
  margin: 0 auto;
  color: #fff;
  background-color: #e6644f;
  border: solid 1px #e6644f;
}
.contact_main table tr td input[type=submit]:hover {
  background-color: #fff;
  color: #e6644f;
}

.contact_list dl .contact_list_item {
  display: flex;
  align-items: center;
}
.contact_list dl .contact_list_item + .contact_list_item {
  margin-top: 35px;
}
.contact_list dl .contact_list_item dt {
  font-size: 18px;
  margin-right: 40px;
}
@media screen and (max-width: 640px) {
  .contact_list dl .contact_list_item dt {
    font-size: 16px;
    margin-right: 20px;
  }
}
.contact_list dl .contact_list_item dd {
  font-size: 18px;
  line-height: 1;
}
@media screen and (max-width: 640px) {
  .contact_list dl .contact_list_item dd {
    font-size: 16px;
  }
}
.contact_list dl .contact_list_item dd .tel {
  color: #231815;
  border-bottom: solid 1px #DB4C34;
}
.contact_list dl .contact_list_item dd .tel:hover {
  border: none;
}
.contact_list dl .contact_list_item dd img {
  width: 100%;
  max-width: 160px;
  height: auto;
}