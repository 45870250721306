@charset "UTF-8";


.tellink{
    color: #333;
    text-decoration: none;
    border-bottom: solid 1px #db4c34;
    
    &:hover{
        border: none;
    }
}

/*------------------------------------*\
    HERO
\*------------------------------------*/
.hero{
  width:100%;
  //height: 530px;
  overflow: hidden;
  margin:0 auto;
  
  @include media_query(){
  	height: 200px;
  }
  
  ul {
    width: 100%;
    height: 525px;
    text-align: left;
    overflow: hidden;
    position: relative;
  
    li {
      top: 0;
      left: 0;
      //margin-top: -14%;
      width: 100%;
      //height: 425px;
      display: none;
      position: absolute;
      
      img{
        width: 100%;
        height: auto;
      }
    }
  }
}







.news_ul{
  margin-bottom: 30px;

  li{
    font-size: 1.8rem;
    
    &:not(:last-child){
      margin-bottom: 20px;
    }
    
    a{
      color: $font-color;
      
      &:hover{
        color: $main-color;
      }
    }
    
    time{
      font-size: 1.2rem;
      margin-right: 30px;
      vertical-align: text-top;
      
      @include media_query(){
      	display: block;
      }
    }
  }
}

.more_btn{
  a{
    color: $font-color;
    
    &:hover{
      color: $main-color;
    }
  }
}








/*------------------------------------*\
    MAIN
\*------------------------------------*/
.main{
  &_inner{
    max-width: 1400px;
    margin: 0 auto;
    border-bottom: solid 1px rgba(#231815, .5%);
    padding-bottom: 110px;
    
    @include media_query(){
    	padding-bottom: 50px;
    }
  }
}

.art{
  padding: 190px 20px 0;
  margin-top: -80px;
  
  @include media_query(){
  	margin-top: 0;
  	padding-top: 50px;
  }
  
  &_sec{
    display: flex;
    max-width: 720px;
    margin: 0 auto;
    
    @include media_query(){
    	flex-direction: column;
    }
    
    &_title{
      width: 29%;
      
      @include media_query(){
      	width: 100%;
      }
      
      h2{
        max-height: 10em;
        font-size: 2.2rem;
        writing-mode: vertical-rl;
        text-orientation: upright;
        letter-spacing: .3em;
        padding-top: .3em;
        
        @include media_query(){
        	writing-mode: horizontal-tb;
        	margin-bottom: 30px;
        }
      }
    }
    &_main{
      width: 71%;
      flex: 1;
      
      @include media_query(){
      	width: 100%;
      }
      
      p{
        font-size: 1.5rem;
        line-height: 2.1;
        text-align: justify;
      }
      
      &_inner{
        &:not(:last-child){
          margin-bottom: 60px;
        }
        
        h3{
          font-size: 18px;
          margin-bottom: 1em;
          letter-spacing: .05em;
          
          small{
            font-size: 1.2rem;
          }
        }
        
        .q_title{
          color: $main-color;
        }
      }
      
      &_wrap{
        display: flex;
        margin-bottom: 50px;
        
        @include media_query(){
        	flex-direction: column;
        	margin-bottom: 30px;
        }
      }
      
      &_arr{
        width: 140px;
        background-image: url(../img/arr_long.png);
        background-repeat: no-repeat;
        background-position: 40px bottom;
        background-size: 10px;
        
        @include media_query(){
        	width: auto;
        	background: none;
        }
        
        h3{
          font-size: 1.8rem;
          background-color: #fff;
          padding-bottom: 20px;
          
          @include media_query(){
          	text-align: left;
          }
        }
      }
      
      .arr_none{
        background: none;
      }
      
      &_text{
        flex:  1;
        padding-left: 20px;
        
        @include media_query(){
        	padding-left: 0;
        }
        
        h3{
          font-size: 1.8rem;
          color: $main-color;
          margin-bottom: 1em;
          text-align: center;
        }
      }
      
      &_img{
        width: 200px;
        text-align: center;
        
        @include media_query(){
        	width: auto;
        	margin-bottom: 10px;
        }
      }
    }
    
    &_img{
      max-width: 980px;
      margin: 110px auto 0;
      
      @include media_query(){
      	margin: 30px auto 0;
      }
      
      ul{
        width: 100%;
        display: flex;
        margin-left: -2%;
        
        @include media_query(){
        	flex-direction: column;
        	margin-left: 0;
        }
        
        li{
          width: 31.33%;
          overflow: hidden;
          margin-left: 2%;
          
          @include media_query(){
          	width: 100%;
          	margin-left: 0;
          	
          	&:not(:last-child){
            	margin-bottom: 20px;
          	}
          }
          
          img{
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}

#about .art_sec_main_inner p, #qa .art_sec_main_inner p, #consultation .art_sec_main_text p, #selfcheck .art_sec_main_text p #staff .art_sec_main_inner p{
  font-size: 1.4rem;
  letter-spacing: .05em;
  
  a{
      &:hover{
          img{
              opacity: .7;
          }
      }
  }
}

#selfcheck .art_sec_main_wrap:not(:last-child){
  margin-bottom: 90px !important;
}

#selfcheck{
  margin-bottom: 55px;
}

#qa{
  margin-bottom: 15px;
}

#shop_info .art_sec_main p{
  font-size: 1.8rem;
}

.line_btn{
    margin: .8em 0;
}

#art_sec_main_area{
    h5{
        font-size: 18px;
        border-bottom: solid 1px #db4c34;
        padding-bottom: 10px;
        
        & + p{
            margin-top: 15px;
        }
    }
    
    p{
        font-size: 1.4rem;
        letter-spacing: .05em;
        
        & + h5{
            margin-top: 50px;
        }
    }
}

.map{
  margin-top: 80px;
  
  @include media_query(){
  	margin-top: 30px;
  }
}

.staff_img{
  margin-bottom: 60px;
  
  max-width: 100%;
  height: auto;
}

.url a{
  text-decoration: underline;
  color: $font-color;
  letter-spacing: .05em;
  
  &:hover{
    text-decoration: none;
    color: $main-color;
  }
}

.blog{
  border-bottom: solid 1px rgba(#231815, .5%);
  padding-bottom: 60px;
  margin-bottom: 20px;
  
  &_art{
    &:not(:last-child){
      margin-bottom: 50px;
      
      @include media_query(){
      	margin-bottom: 20px;
      }
    }
  }
  
  &_time{
    display: block;
    font-size: 1.2rem;
    margin-bottom: 10px;
    letter-spacing: .05em;
  }
  
  &_title{
    font-size: 1.8rem;
    margin-bottom: 5px;
    letter-spacing: .05em;
    
    a{
      color: #db4c34;
    }
  }
  
  &_cat{
    font-size: 1.2rem !important;
    letter-spacing: .05em;
    
    a{
      color: #db4c34;
    }
  }
}

.read_more{
  margin-bottom: 30px;
  
  a{
    color: $font-color;
    
    &:hover{
      color: $main-color;
    }
  }
}

.all_cat{
  display: flex;
  flex-wrap: wrap;
  font-size: 1.2rem;
  letter-spacing: .05em;
  
  &:before{
    content: "カテゴリ：";
  }
  
  li{
    
    &:not(:last-child){
      //margin-right: 5px;
      
      &::after{
        content: " / ";
      }
    }
    
    a{
      color: $font-color;
      padding: 10px;
      
      &:hover{
        color: $main-color;
      }
    }
  }
}




.shop_slider{
  max-width: 930px;
  margin: -82px auto 0;
  padding-top: 82px;
  
  @include media_query(){
  	margin-top: 0;
  	padding-top: 0;
  }
  
  .slick-prev, .slick-next{
    height: 36px !important;
    opacity: .8;
  }
  
  .slick-prev:before,
  .slick-next:before {
    color: black;
  }
  img{
    width: 100%;
    height: auto;
  }
  
  &_main{
    margin-bottom: 25px;
  }
  
  &_nav{
    margin-left: -24px;
    
    @include media_query(){
    	display: none;
    }
    
    li{
      margin-left: 24px;
    }
  }
  
}





.tal{
  text-align: left !important;
}
