@charset "UTF-8";

.pan{
  margin-bottom: 30px;
  
  @include media_query(){
  	margin-bottom: 20px;
  }
    
  ul{
    display: flex;
    align-items: center;
    
    li{
      font-size: 1.2rem;
      
      &:not(:last-child)::after{
        content: ">";
        padding: 0 5px;
      }
      
      a{
        color: $font-color;
        
        &:hover{
          color: $main-color;
        }
      }
    }
  }
}

.single{
  max-width: 780px;
  padding: 100px 20px 0;
  margin: 0 auto;
    
  display: flex;
  
  @include media_query(){
    max-width: 100%;
  	flex-direction: column;
  	padding: 20px 20px 0;
  }
  
  &_main{
    max-width: 520px;
    
    @include media_query(){
      max-width: 100%;
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: solid 1px #ccc;
    }
  }
  
  &_info{
    
    time{
      display: block;
      font-size: 1.2rem;
      margin-bottom: 30px;
      
      @include media_query(){
      	margin-bottom: 20px;
      }
    }
    
    h3{
      color: $main-color;
      font-size: 2.2rem;
      margin-bottom: .5em;
    }
    
    .cat{
      margin-bottom: 30px;
      font-size: 1.2rem;
      
      @include media_query(){
      	margin-bottom: 20px;
      }
      
      a{
        color: $font-color;
        
        &:hover{
          color: $main-color;
        }
      }
    }
  }
  
  &_art{
    
    //ブログ内スタイル
    &_main{
      p{
        font-size: 1.5rem;
        line-height: 2;
      }
      
      img{
        vertical-align: bottom;
        width: 100%;
        height: auto;
        margin: 30px 0;
      }
    }
    
    
  }
  
  
}

.lnav{
  width: 100px;
  margin-right: 120px;
  
  @include media_query(){
    width: 100%;
  	order: 2;
  }
  
  h2{
    max-height: 10em;
    font-size: 2.2rem;
    writing-mode: vertical-rl;
    text-orientation: upright;
    letter-spacing: .3em;
    padding-top: .3em;
    margin: 50px 0 100px;
    
    @include media_query(){
    	display: none;
    }
  }
}

.cat_list, .archive_list{
  
  h4{
    font-size: 1.2rem;
    margin-bottom: .8em;
  }
  
  ul{
    @include media_query(){
    	display: flex;
    	flex-wrap: wrap;
    	justify-content: space-between;
    }
    
    li{
      font-size: 1.2rem;
      
      @include media_query(){
      	width: 48%;
      }
      
      &:not(:last-child){
        margin-bottom: .8em;
      }
      
      a{
        display: block;
        color: $font-color;
        
        @include media_query(){
        	padding: .5em 0;
        }
        
        &:hover{
          color: $main-color;
        }
        
        &::before{
          content: "▷";
          margin-right: 5px;
        }
      }
    }
  }
}

.cat_list{
  margin-bottom: 100px;
  
  @include media_query(){
  	margin-bottom: 30px;
  }
}

.sp_title{
  display: none;
  font-size: 2.2rem;
  letter-spacing: .3em;
  padding-top: .3em;
  margin-bottom: 30px;
  
  @include media_query(){
  	display: block;
  }
}
